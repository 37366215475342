html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  min-height: 100%;
  background-color: #082d14;
}

a {
  text-decoration: none;
  color: black;
}

.category-btn:hover {
  background-color: #FC1503 !important;
  color: white !important;
}

.category-btn:hover span{
  color: white !important;
}

.react-player {
  height: 77vh !important;
  width: 100% !important;
}

.search-bar{
  border:none;
  outline:none;
  width:350px;
}

.category-btn{
  font-weight: bold !important;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor:pointer;
  background: transparent;
  outline: none;
  border: none;

  padding: 7px 15px;
  margin: 10px 0px;
  border-radius: 20px;
  transition: all 0.3s ease;
}

@media screen and (max-width: 900px) {
  .category-btn{
    margin: 10px;
  }
}

@media screen and (max-width:800px){
  .copyright{
    display:none !important;
  }
}

@media screen and (max-width: 600px) {
  .scroll-horizontal {
    overflow: auto !important;
  }

  .react-player {
    height: 45vh !important;
  }
  .search-bar{
    width:200px;
  }
}
*{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

.octane-box:hover {
  height: 155px;
  cursor: pointer;
}

.table-image:hover {
  box-shadow: 0 8px 16px 0 #fff;
}

:root {
  --speed: 500ms;
}

.social-btn:hover {
  box-shadow: 0 3px 10px #fff;
}

.social-account:hover {

  cursor: pointer;
  
}

.signup-button:hover {
  cursor: pointer;
}


.bgkc-banner:hover {
  cursor: pointer;
}

.socialIconsMenu:hover {
  cursor: pointer;
  background-color: gray;
  border-radius: 10px;
}

.leaderboard-toggle {
  cursor: pointer;
}

.leaderboard-toggle:hover {
  cursor: pointer;
  color: #e4e2e2;
}

table, tr, td, th {
  border-spacing: 0;
  border-collapse:separate;
  border-spacing:0 5px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.uploader__file_input {
  display: none;
}

.uploader__file {
  display: none;
}

::placeholder {
  color: #fff;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12 -18 */
  color: #fff;
}