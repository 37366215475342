@font-face {
    font-family: "EllasKitchen1";
    src: local("EllasKitchen1"),
     url("./ella01-webfont-webfont.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "DharmaGothic";
    src: local("DharmaGothic"),
     url("./dharma-gothic-e-heavy-italic.ttf") format("truetype");
    font-weight: bold;
}